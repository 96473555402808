import type { PricedVariant } from '@medusajs/medusa/dist/types/pricing';

export function getCheapestVariant(variants: PricedVariant[]): PricedVariant | null {
	if (!variants?.length) return null;
	return variants.reduce((prev, current) => {
		const pcp = prev?.calculated_price;
		const ccp = current?.calculated_price;
		return (ccp || 0) <= (pcp || 0) ? current : prev;
	});
}
