import type { Product } from '@medusajs/medusa';
import type { PricedProduct } from '@medusajs/medusa/dist/types/pricing';

type ProductImages =
	| Pick<PricedProduct, 'thumbnail' | 'images'> //
	| Pick<Product, 'thumbnail' | 'images'>;

export function getThumbnailUrl(product: ProductImages): string | undefined {
	return product.thumbnail || product.images?.[0]?.url;
}
