import type { PricedProduct } from '@medusajs/medusa/dist/types/pricing';
import { useCart } from '@src/lib/medusa/hooks/use-cart';
import { medusaClient } from '@src/lib/medusa/medusa-client';
import { queryKeys } from '@src/lib/react-query/query-keys';
import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

export function useFeaturedProducts(): UseQueryResult<PricedProduct[]> {
	const { cart } = useCart();

	return useQuery<PricedProduct[]>(
		[queryKeys.featuredProducts, cart?.id], //
		async () => {
			if (!cart?.id) return [];

			try {
				const res = await medusaClient.products.list({
					is_giftcard: false,
					order: '-updated_at',
					cart_id: cart?.id,
					limit: 20,
				});

				return res.products;
			} catch (e) {
				console.error('Error getting featured products', e);
				return [];
			}
		},
	);
}
