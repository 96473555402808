export function formatPrice(price?: number | null, currency = 'USD'): string {
	let myPrice = price || 0;
	if (myPrice % 1 === 0) {
		myPrice /= 100.0;
	}
	return Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: currency,
	}).format(myPrice);
}
